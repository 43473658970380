import logo from "./logo.jpeg";
import { ReactComponent as ReactLogo } from "./headerlogo.svg";
import React from "react";
import "./App.css";
import Lottie from 'react-lottie';
import confirmAnimData from './lotties/confirmation';
import invalidAnimData from './lotties/91846-invalid';
// import { BiRefresh, BiStar } from "react-icons/bi";
import { MdResetTv } from "react-icons/md";
//import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
// import { scan } from "micromatch";
//import { AiFillCheckCircle } from "react-icons/ai";
//import { RiErrorWarningFill } from "react-icons/ri";

//import { ImWarning, ImCheckmark } from "react-icons/im";
import { fetchData, checkData, fetchPoints } from "./useApi";
// import { Text } from "react";

// const value = Math.floor(Math.random() * 90000) + 10000000;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: confirmAnimData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const invalidOptions = {
  loop: true,
  autoplay: true,
  animationData: invalidAnimData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

let urlElements = "";
// let store_list = "";
// let store_name = "";
// let qrName = "";
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: false,
      mobile: "",
      name: "",
      errors: {},
      urlElements: "",
      checkin_store_name: "",
      store_list: "",
      result: "",
      scan: false,
      qrButton: false,
      points: false,
      pointstext: '',
      qrcodevalue: "",
      message: false,
      bodymsg: "",
      bodymsg_head: "",
      bodymsg_text1: "",
      bodymsg_text2: "",
      bodymsg_text: "",
      bodymsg_valid_to: "",
      submit: true,
      offerList: false,
      offerCard: "",
      offerItems: [],
      maindata: {},
      msg: ''
    };
  }

  onInputchange = (e) => {

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = () => {
    if (this.state.mobile.length === 10) {
      // this.setState({
      //   number: true,
      //   // qrButton: true,
      //   // points: true,
      //   offerList: true,
      // });

      // this.handleCheckin();
      // this.handlePoints()
      this.handleMainData()
    }
  };

  handleFetch = () => {
    //console.log(this.state.qrcodevalue.split("?"))

    let qrName = this.state.qrcodevalue.split("?");
    if (qrName[1] != null) {
      let api_params = qrName[1].split("&");

      if (api_params[0] != null) {
        this.setState({}, async () => {
          let methodType = api_params[0];
          let qr_code_val = "";
          if (methodType === "deptcheckin") {
            this.setState({ offerList: true })
            qr_code_val =
              api_params[1] + "&" + api_params[2] + "&" + api_params[3];
          } else {
            this.setState({ offerList: false })
            qr_code_val = qrName[1];
          }
          let qr_code_val1 = qr_code_val.replace(/%20/g, " ");
          let qr_store_name = api_params[1].replace(/%20/g, " ");
          var oData = {
            method: "general", //api_params[0],
            body: {
              mobileno: this.state.mobile,
              name: this.state.name,
              qrcode: qr_code_val1,
              storename: qr_store_name, //"Rise Naperville",
            },
          };
          console.log(oData);
          let data = await fetchData({ body: oData, methodType })
          this.setState({
            ...data,
            scan: false,
            message: true,
            number: false,
            submit: false,
            name: false
          });
        });
      }
    }
  };

  handleCheckin = () => {
    urlElements = window.location.href.split("?");
    // store_list = urlElements[1].split('&')
    // store_name = store_list[1]
    let qr_code = urlElements[1];
    //store_name = store_name.replace(/%20/g, " ")
    //console.log(store_list)
    // this.setState({
    //   checkin_store_name: "Rise Mundelein"
    // })
    //let storeName = qrName[3].split("&")
    if (qr_code == null) {
      alert("Error while processing your request , Please try it again!");
      return null;
    }
    this.setState(
      {
        //checkin_store_name: store_name
      },
      async () => {
        var oData = {
          method: "general",
          body: {
            mobileno: this.state.mobile,
            fullname: this.state.name,
            //randomno: value,
            qrcode: qr_code,
            //"Rise Naperville",
          },
        };
        console.log(oData);

        let data = await checkData({ body: oData, qr_code })
        this.setState({
          ...data,
          scan: false,
          message: true,
          number: false,
          name: false,
          submit: false,
        });
      }
    );
  };
  handlePoints = () => {

    // if (!window.location.href.contains("loyalty")) {
    //   return null;
    // }
    urlElements = window.location.href.split("?");
    // store_list = urlElements[1].split('&')
    // store_name = store_list[1]
    let qr_code = urlElements[1];
    //store_name = store_name.replace(/%20/g, " ")
    //console.log(store_list)
    // this.setState({
    //   checkin_store_name: "Rise Mundelein"
    // })
    //let storeName = qrName[3].split("&")
    if (qr_code == null) {
      alert("Error while processing your request , Please try it again!");
      return null;
    }

    this.setState({
    },
      async () => {
        var oData = {
          method: "loyalty_query",
          body: {
            mobileno: this.state.mobile,
            qrcode: qr_code,//'loyalty_query&GTI - Rise Chambersburg',
          },
        };
        console.log('odata', oData);

        let data = await fetchPoints({ body: oData })
        console.log(data, "data")
        let bodyContent = data.value.body;
        console.log('body', bodyContent)
        var obj = await JSON.parse(bodyContent)
        console.log(obj, 'obj convert')
        console.log(obj.v_customer_name, 'name')
        console.log(bodyContent.v_customer_name, 'main')
        let bodyvalue = JSON.parse(data.value);
        console.log('body value', bodyvalue.v_available_points)
        this.setState({
          ...data,
          scan: false,
          message: true,
          points: true,
          pointstext: data.value,
          number: false,
          name: false,
          submit: false,
          maindata: obj,
        });
      })
  }

  handleMainData = async () => {
    // if (!window.location.href.contains("loyalty")) {
    //   return null;
    // }
    urlElements = window.location.href.split("?");
    // store_list = urlElements[1].split('&')
    // store_name = store_list[1]
    let qr_code = urlElements[1];
    //store_name = store_name.replace(/%20/g, " ")
    //console.log(store_list)
    // this.setState({
    //   checkin_store_name: "Rise Mundelein"
    // })
    //let storeName = qrName[3].split("&")
    if (qr_code == null) {
      alert("Error while processing your request , Please try it again!");
      return null;
    }
    var oData = {
      method: "loyalty_query",
      body: {
        mobileno: this.state.mobile,
        qrcode: qr_code,//'loyalty_query&GTI - Rise Chambersburg',
      },
    };
    console.log('odata', oData);

    let data = await fetchPoints({ body: oData })
    console.log(data, "data")
    let bodyContent = data.value.body;
    console.log('body', bodyContent)
    var obj = await JSON.parse(bodyContent)
    console.log(obj, 'obj convert')
    console.log(obj.v_customer_name, 'name')
    console.log(bodyContent.v_customer_name, 'main')
    // let bodyvalue = JSON.parse(data.value);
    // console.log('body value', bodyvalue.v_available_points)
    this.setState({
      scan: false,
      message: true,
      points: true,
      pointstext: data.value,
      number: false,
      name: false,
      submit: false,
      maindata: obj,
      msg: obj
    });
  }

  handleScan = (data) => {
    if (data) {
      //console.log(data)
      this.setState(
        {
          qrcodevalue: data,
          message: false,
          bodymsg_head: "dummy",
          bodymsg_text: "",
          bodymsg_text1: "",
          bodymsg_text2: "",
          bodymsg_valid_to: "",
        },
        () => {
          this.handleFetch();
          this.setState({ scan: false });
        }
      );
    }
  };
  // handleScan = () => {
  //   this.setState({ scan: true });
  // };
  handleError = (err) => {
    console.error(err);
  };

  phoneValidation() {
    // eslint-disable-next-line
    const regex = /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;

    // const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    // if (this.state.mobile.length < 10) {
    //   return false
    // }
    return !(!this.state.mobile || regex.test(this.state.mobile) === false);
  }
  emailValidation() {
    // eslint-disable-next-line
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // const regex =
    //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    console.log(regex.test(this.state.mobile), "laptop")
    return !(!this.state.mobile || regex.test(this.state.mobile) === false);
  }

  formValidation = () => {
    //const { mobile } = this.state;
    let isValid = true;
    const mobilenovalid = this.phoneValidation();
    const emailidvaild = this.emailValidation();
    const errors = {};
    if ((mobilenovalid === false) && (emailidvaild === false)) {
      isValid = false
      errors.mobile = "Enter Valid Mobile/Email"
    }
    else {
      isValid = true
      errors.mobile = ""
    }
    // else if (mobile.trim().length < 10) {
    //   errors.mobile = "Enter a Valid Mobile Number";
    //   isValid = false;
    // }
    this.setState({ errors });
    return isValid;
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.formValidation();
  }
  render() {
    const { errors } = this.state;

    return (
      <div className="App">
        <header style={{ backgroundColor: "#045624", height: "100px" }}>
          <ReactLogo style={{ height: "120px" }} />
        </header>

        <div className="App-header">

          <img
            src={logo}
            className="App-logo"
            alt="logo"
            style={{ height: "80px", paddingBottom: '20px' }}
          />
          {console.log(this.state.errors, "spit")}
          {console.log(this.state.msg, 'msg')}
          {console.log(Object.keys(this.state.maindata).length, 'maindata')}
          {
            Object.keys(this.state.maindata).length !== 0 && this.state.points === true ? (
              // <div>
              //   <div className="row">
              //     <div className="text-center">
              //   <h4 style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif' }}>
              //     {this.state.maindata.v_customer_name}
              //   </h4>
              //   </div>
              //   </div>
              //   <h5 className="text-center" style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif', width: "80%", justifyContent: 'center'}}>
              //     Points : {this.state.maindata.v_available_points}
              //   </h5>
              //   <h5 className="text-center" style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif', width: "150%", justifyContent : 'center'}}>
              //     {/* {this.state.pointstext.body.replace(/['"]+/g, "")} */}
              //     Last Access Date : {this.state.maindata.v_last_access_date}
              //   </h5>
              //   <button className="goback" style={{width : 100}}>
              //     <Link to="/loyalty" className='link'>
              //       Go Back
              //     </Link>
              //   </button>
              // </div>
              <div>
                <table className="table table-bordered" style={{ borderStyle: 'none', color: "#045624" }}>
                  <tbody style={{ borderStyle: 'none' }}>
                    <tr style={{ borderStyle: 'none' }}>
                      <td style={{ textAlign: 'initial', borderStyle: 'none' }}>Name</td>
                      <td style={{ borderStyle: 'none', textAlign: 'initial', }} >{this.state.maindata.v_customer_name}</td>
                    </tr>
                    <tr style={{ borderStyle: 'none' }}>
                      <td style={{ textAlign: 'initial', borderStyle: 'none' }}>Points</td>
                      <td style={{ borderStyle: 'none', textAlign: 'initial', }}>{this.state.maindata.v_available_points}</td>
                    </tr>
                    <tr style={{ borderStyle: 'none' }}>
                      <td style={{ textAlign: 'initial', borderStyle: 'none' }}>Last Access Date</td>
                      <td style={{ borderStyle: 'none', textAlign: 'initial' }}>{this.state.maindata.v_last_access_date}</td>
                      {/* <td style={{ borderStyle: 'none', textAlign: 'initial' }}>31/10/2022</td> */}
                    </tr>
                    <tr style={{ borderStyle: 'none' }}>
                      {/* <td style={{ textAlign: 'initial', borderStyle: 'none' }}>Last Access Date</td> */}
                      {/* <td style={{ borderStyle: 'none', textAlign: 'initial' }}>31/10/2022</td> */}
                    </tr>
                  </tbody>
                </table>
                {this.state.maindata.valid_flag && this.state.maindata.valid_flag === "Y" ?
                  <div style={{
                    fontSize: '28px', color: '#045624', display: "flex",
                    justifyContent: 'center', alignItems: 'center'
                  }} >
                    <div>
                      <Lottie 
                      options={defaultOptions}
                        height={24}
                        width={24}
                      />
                    </div>
                     Valid
                  </div> : (
                    this.state.maindata.valid_flag === 'N' ? <div style={{
                      fontSize: '28px', color: '#CC474C', display: "flex",
                      justifyContent: 'center', alignItems: 'center'
                    }} >
                      <Lottie 
                      options={invalidOptions}
                        height={64}
                        width={64}
                      />
                      Invalid
                    </div> : ""
                  )
                }
                {/* <button className="goback" style={{ width: 100, color: "#045624", borderRadius: '5px' }}>
                  <Link style={{ color: "#045624" }} to="/loyalty" className='link'>
                    Go Back
                  </Link>
                </button> */}
              </div>
            ) : ""
          }
          {this.state.qrButton === true ? (
            <button
              className="button button1"
              onClick={() => {
                this.setState({
                  scan: true,
                  bodymsg_head: "",
                  bodymsg_text: "",
                  bodymsg_text1: "",
                  bodymsg_text2: "",
                  bodymsg_valid_to: "",
                });
                this.handleScan();
              }}
            >
              Scan QR
            </button>
          ) : (
            ""
          )}
          {this.state.scan === true ? (
            <>
              {" "}
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: "100%" }}
              />
              <p style={{ color: "green" }}>{this.state.result}</p>
            </>
          ) : (
            ""
          )}
          {this.state.submit === true ? (
            <form onSubmit={this.onSubmit}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  name='mobile'
                  onChange={this.onInputchange}
                  value={this.state.mobile}
                  style={{ marginTop: "30px", width: 170, height: 20, fontSize: 12 }}
                  placeholder="Enter Mobile No/Email Id"
                ></input>
                {/* {errors.mobile && <p className="errors">{errors.mobile}</p>} */}
                {
                  Object.keys(errors).map((key) => {
                    return <div style={{ color: 'red', fontSize: '14px' }} key={key}>{errors[key]}</div>
                  })
                }
                {/* <input type="number" name="mobile" value={this.state.mobile} onChange={this.onInputchange} style={{ marginTop: "30px" }} placeholder="Enter Mobile No" />
                {errors.mobile && <p className="errors">{errors.mobile}</p>} */}
              </div>
              <div style={{ marginTop: "10px" }}>
                <button className="button button1" onClick={this.handleClick} type="submit">
                  Submit
                </button>
                <button
                  className="button button2"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    this.setState({
                      number: false,
                      mobile: "",
                      scan: false,
                      qrButton: false,
                      message: false,
                      bodymsg_head: "",
                      bodymsg_text: "",
                      bodymsg_text1: "",
                      bodymsg_text2: "",
                      bodymsg_valid_to: "",
                    })
                  }
                >
                  <MdResetTv />
                </button>

              </div>
            </form>
          ) : (
            ""
          )}
          {this.state.offerList === true ? (
            <>
              {this.state.offerItems.map((e) => (
                <div
                  style={{
                    display: "flex",
                    border: "1px solid grey",
                    marginTop: "50px",
                    borderRadius: "30px",
                    padding: "10px",
                    width: "80vw",
                    height: "30vw"
                  }}
                >
                  <div>
                    <img
                      src={e.imgsrc}
                      className="App-logo"
                      alt="logo"
                      style={{
                        height: "60px",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontSize: "20px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        marginTop: "2px",
                      }}
                    >
                      {e.title}
                    </div>



                    <div
                      style={{
                        color: "grey",
                        fontSize: "15px",
                        marginTop: "18px",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #07562C",
                          marginRight: "10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          padding: "3px",
                          background: "#07562C",
                          color: "white",
                          width: "30px"
                        }}
                      >
                        {e.cat1}
                      </span>
                      <span
                        style={{
                          border: "1px solid #710e68",
                          marginRight: "10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          padding: "3px",
                          background: "#710e68",
                          color: "white",
                        }}
                      >
                        {e.cat2 !== "null" ? e.cat2 : "null"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>

        <footer style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0
        }}>
          <div class="copyright-large" style={{ color: "white", backgroundColor: "#045624", fontSize: "15px" }}>
            © 2021 Green Thumb Industries (GTI).<br></br>All Rights
            Reserved.
          </div>
        </footer>
      </div>
    );
  }
}
export default Loyalty;

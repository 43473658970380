import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './Landing';
import Loyalty from './Loyalty';
export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="loyalty" element={<Loyalty />} />
        <Route path="/" element={<Landing />} />
      </Routes>

    </Router>
  )
}
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Landing from './Landing';
// import Loyalty from './Loyalty';
// // import 
// export default function App() {
//   return (
//     <Router>
//       <Switch>
//         {/* <Route path="loyalty" element={<Loyalty />} />
//         <Route path="/" element={<Landing />} /> */}
//         <Route path="/loyalty">
//           <Loyalty />
//         </Route>
//         <Route path="/">
//           <Landing />
//         </Route>
//       </Switch>
//     </Router>
//   )
// }



export const fetchData = async ({ body, methodType }) => {
  const responses = await fetch(
    process.env.URL,
    {
      method: "post",
      body: JSON.stringify(body),
    }
  );
  const json = await responses.json();
  if (json.statuscode === 200) {
    // alert("Thanks for posting the data");
    //alert(json.body)
    //const htmlString = "{"head":"value"}";
    //this.state.number = json.body.message
    let jsonObject = JSON.parse(json.body);
    console.log(json.body);
    let header = "Welcome To " + jsonObject.head;
    if (methodType === "checkout") {
      header = "";
    }

    //let validmsg = "Valid Till: " + jsonObject.valid_to;
    //this.setState.bodymsg = "test string";// <div dangerouslySetInnerHTML={{ __html: json.body.message }}></div>;
    return {
      bodymsg_head: header,
      bodymsg_text: jsonObject.text1,
      bodymsg_text1: jsonObject.text2,
      bodymsg_text2: jsonObject.text3,
      bodymsg_valid_to: jsonObject.valid_to,
    };

  }
}

export const checkData = async ({ body, qr_code }) => {
  const responses = await fetch(
    process.env.URL,
    {
      method: "post",
      body: JSON.stringify(body),
    }
  );

  const json = await responses.json();
  if (json.statuscode === 200) {
    let jsonObject = JSON.parse(json.body);
    console.log(json.body);

    let header = "Welcome To " + jsonObject.head;
    if (qr_code.includes("checkout")) {
      header = "";
    }
    if (qr_code.includes("deptcheckin")) {
      this.setState({ offerList: true })
      header = "";
    } else {
      this.setState({ offerList: false })
    }
    console.log(jsonObject);
    //let validmsg = "Valid Till: " + jsonObject.valid_to;

    var offers_list = jsonObject.offers;

    var result = Object.values(offers_list);

    var iterator = result.values();
    var offers = [];

    // Here all the elements of the array is being printed.
    for (let elements of iterator) {
      //  var itr = elements.values();
      offers.push({
        imgsrc: elements[3],
        title: elements[0],
        cat1: elements[1] !== "null" ? elements[1] : "",
        cat2: elements[2] !== "null" ? elements[2] : "",
      });
    }

    console.log(offers, "offers");

    return {
      offerItems: offers,
      offerCard: jsonObject.body,
      bodymsg_head: header,
      // bodymsg_text: jsonObject.text1,
      bodymsg_text: jsonObject.text1,
      bodymsg_text1: jsonObject.text2,
      bodymsg_text2: jsonObject.text3,
      bodymsg_valid_to: jsonObject.valid_to,
    }

  }
}
//https://777vqp94he.execute-api.us-east-1.amazonaws.com/Dev/
//https://yovpsyi7f7.execute-api.us-east-1.amazonaws.com/prod/
export const fetchPoints = async ({ body }) => {
  const responses = await fetch(

    'https://yovpsyi7f7.execute-api.us-east-1.amazonaws.com/prod/',
    {
      method: "post",
      body: JSON.stringify(body),
    }
  );
  const json = await responses.json();
  // console.log(json, "json")
  if (json.statuscode === 200) {
    let jsonObject = JSON.parse(json.body);
    console.log(json.body, 'jsonBody');
    console.log(jsonObject, 'jsonObjects')
    //var message = jsonObject.message;
    return {
      value: json
    }
  }
  else {
    return { value: json }
  }
}